[x-cloak] { display: none !important; }

@tailwind base;
@tailwind components;
@tailwind utilities;

.light {
    --background: 255, 255, 255;
    --background-nav: 245, 245, 245;
    --background-card: 255, 255, 255;
    --background-button: 37, 99, 235;
    --background-button-hover: 74, 123, 232;
    --border: 229, 231, 235;
    --shadow: 0 2px 4px 0 #00000015;
    --tw-shadow-color: 229, 231, 235;
    --tw-ring-offset-shadow: 0 0 #0000;
    --tw-ring-shadow: 0 0 #0000;
    --card: 255, 255, 255;
    --copy-primary: 23, 23, 23;
    --copy-secondary: 115, 115, 115;
    --cta: 139, 92, 246;
    --active: 249, 250, 251;
    --active-text: 37, 99, 235;
    --hover-item: 249, 250, 251;
    --hover-text: 37, 99, 235;
    --text-primary: 55, 65, 81;
    --text-secondary: 107, 114, 128;
    --text-button: 255, 255, 255;
    --text-button-hover: 55, 65, 81;
    background: rgba(var(--background));
}

.dark {
    --background: 17, 24, 39;
    --background-nav: 0, 0, 0;
    --background-card: 31, 41, 55;
    --background-button: 37, 99, 235;
    --background-button-hover: 37, 99, 235 / 80%;
    --border: 148, 163, 184;
    --shadow: 0 2px 4px 0 #000000;
    --tw-shadow-color: 229, 231, 235;
    --tw-ring-offset-shadow: 0 0 #0000;
    --tw-ring-shadow: 0 0 #0000;
    --card: 23, 23, 23;
    --copy-primary: 250, 250, 250;
    --copy-secondary: 115, 115, 115;
    --cta: 99, 102, 241;
    --active: 31 41 55;
    --active-text: 255, 255, 255;
    --hover-item: 31 41 55;
    --hover-text: 255, 255, 255;
    --text-primary: 156, 163, 175;
    --text-secondary: 107, 114, 128;
    --text-button: 156, 163, 175;
    --text-button-hover: 55, 65, 81;
    background: rgba(var(--background));
}

.header-background-filters {
    filter: opacity(0.9) brightness(0.4);
}

.slideshow {
    background-size: cover;
    background-repeat: no-repeat;
    animation: slideshow 15s ease-in-out infinite;
    background-image: url("../images/bg-1.png");
}

@keyframes slideshow {
    0% {
        background-image: url("../images/bg-1.png");
    }

    25% {
        background-image: url("../images/bg-2.png");
    }

    50% {
        background-image: url("../images/bg-3.png");
    }

    75% {
        background-image: url("../images/bg-6.png");
    }

    100% {
        background-image: url("../images/bg-1.png");
    }
}

@layer components {
    .sidebar-item {
        @apply flex gap-x-3 rounded-md p-2 text-sm font-semibold leading-6 text-text-primary hover:bg-hover-item hover:text-hover-text;
    }

    .header-background {
        @apply absolute inset-0 -z-10 h-full w-full object-cover object-left md:object-center w-auto sm:w-full header-background-filters;
    }
}

.shadow-sm {
    --tw-shadow: var(--shadow);
    --tw-shadow-colored: 0 1px 2px 0 var(--tw-shadow-color);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

@font-face {
    font-family: "Noto Color Emoji";
    src: url("../fonts/NotoColorEmoji-Regular.ttf");
    unicode-range: U+1f1e6-1f1ff;
}
  
.country {
    font-family: "Noto Color Emoji";
}
