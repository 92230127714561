[x-cloak] {
  display: none !important;
}

*, :before, :after, ::backdrop {
  --tw-border-spacing-x: 0;
  --tw-border-spacing-y: 0;
  --tw-translate-x: 0;
  --tw-translate-y: 0;
  --tw-rotate: 0;
  --tw-skew-x: 0;
  --tw-skew-y: 0;
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  --tw-pan-x: ;
  --tw-pan-y: ;
  --tw-pinch-zoom: ;
  --tw-scroll-snap-strictness: proximity;
  --tw-gradient-from-position: ;
  --tw-gradient-via-position: ;
  --tw-gradient-to-position: ;
  --tw-ordinal: ;
  --tw-slashed-zero: ;
  --tw-numeric-figure: ;
  --tw-numeric-spacing: ;
  --tw-numeric-fraction: ;
  --tw-ring-inset: ;
  --tw-ring-offset-width: 0px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: #3b82f680;
  --tw-ring-offset-shadow: 0 0 #0000;
  --tw-ring-shadow: 0 0 #0000;
  --tw-shadow: 0 0 #0000;
  --tw-shadow-colored: 0 0 #0000;
  --tw-blur: ;
  --tw-brightness: ;
  --tw-contrast: ;
  --tw-grayscale: ;
  --tw-hue-rotate: ;
  --tw-invert: ;
  --tw-saturate: ;
  --tw-sepia: ;
  --tw-drop-shadow: ;
  --tw-backdrop-blur: ;
  --tw-backdrop-brightness: ;
  --tw-backdrop-contrast: ;
  --tw-backdrop-grayscale: ;
  --tw-backdrop-hue-rotate: ;
  --tw-backdrop-invert: ;
  --tw-backdrop-opacity: ;
  --tw-backdrop-saturate: ;
  --tw-backdrop-sepia: ;
  --tw-contain-size: ;
  --tw-contain-layout: ;
  --tw-contain-paint: ;
  --tw-contain-style: ;
}

*, :before, :after {
  box-sizing: border-box;
  border: 0 solid #e5e7eb;
}

:before, :after {
  --tw-content: "";
}

html, :host {
  -webkit-text-size-adjust: 100%;
  tab-size: 4;
  font-feature-settings: normal;
  font-variation-settings: normal;
  -webkit-tap-highlight-color: transparent;
  font-family: Inter var, ui-sans-serif, system-ui, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
  line-height: 1.5;
}

body {
  line-height: inherit;
  margin: 0;
}

hr {
  color: inherit;
  border-top-width: 1px;
  height: 0;
}

abbr:where([title]) {
  text-decoration: underline dotted;
}

h1, h2, h3, h4, h5, h6 {
  font-size: inherit;
  font-weight: inherit;
}

a {
  color: inherit;
  -webkit-text-decoration: inherit;
  text-decoration: inherit;
}

b, strong {
  font-weight: bolder;
}

code, kbd, samp, pre {
  font-feature-settings: normal;
  font-variation-settings: normal;
  font-family: ui-monospace, SFMono-Regular, Menlo, Monaco, Consolas, Liberation Mono, Courier New, monospace;
  font-size: 1em;
}

small {
  font-size: 80%;
}

sub, sup {
  vertical-align: baseline;
  font-size: 75%;
  line-height: 0;
  position: relative;
}

sub {
  bottom: -.25em;
}

sup {
  top: -.5em;
}

table {
  text-indent: 0;
  border-color: inherit;
  border-collapse: collapse;
}

button, input, optgroup, select, textarea {
  font-feature-settings: inherit;
  font-variation-settings: inherit;
  font-family: inherit;
  font-size: 100%;
  font-weight: inherit;
  line-height: inherit;
  letter-spacing: inherit;
  color: inherit;
  margin: 0;
  padding: 0;
}

button, select {
  text-transform: none;
}

button, input:where([type="button"]), input:where([type="reset"]), input:where([type="submit"]) {
  -webkit-appearance: button;
  background-color: #0000;
  background-image: none;
}

:-moz-focusring {
  outline: auto;
}

:-moz-ui-invalid {
  box-shadow: none;
}

progress {
  vertical-align: baseline;
}

::-webkit-inner-spin-button {
  height: auto;
}

::-webkit-outer-spin-button {
  height: auto;
}

[type="search"] {
  -webkit-appearance: textfield;
  outline-offset: -2px;
}

::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  -webkit-appearance: button;
  font: inherit;
}

summary {
  display: list-item;
}

blockquote, dl, dd, h1, h2, h3, h4, h5, h6, hr, figure, p, pre {
  margin: 0;
}

fieldset {
  margin: 0;
  padding: 0;
}

legend {
  padding: 0;
}

ol, ul, menu {
  margin: 0;
  padding: 0;
  list-style: none;
}

dialog {
  padding: 0;
}

textarea {
  resize: vertical;
}

input::placeholder, textarea::placeholder {
  opacity: 1;
  color: #9ca3af;
}

button, [role="button"] {
  cursor: pointer;
}

:disabled {
  cursor: default;
}

img, svg, video, canvas, audio, iframe, embed, object {
  vertical-align: middle;
  display: block;
}

img, video {
  max-width: 100%;
  height: auto;
}

[hidden] {
  display: none;
}

.sidebar-item {
  color: rgba(var(--text-primary));
  border-radius: .375rem;
  column-gap: .75rem;
  padding: .5rem;
  font-size: .875rem;
  font-weight: 600;
  line-height: 1.5rem;
  display: flex;
}

.sidebar-item:hover {
  background-color: rgba(var(--hover-item));
  color: rgba(var(--hover-text));
}

.header-background {
  z-index: -10;
  object-fit: cover;
  object-position: left;
  filter: opacity(.9) brightness(.4);
  width: auto;
  height: 100%;
  position: absolute;
  inset: 0;
}

@media (width >= 640px) {
  .header-background {
    width: 100%;
  }
}

@media (width >= 768px) {
  .header-background {
    object-position: center;
  }
}

.sr-only {
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border-width: 0;
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  position: absolute;
  overflow: hidden;
}

.pointer-events-none {
  pointer-events: none;
}

.fixed {
  position: fixed;
}

.absolute {
  position: absolute;
}

.relative {
  position: relative;
}

.sticky {
  position: sticky;
}

.inset-0 {
  inset: 0;
}

.inset-x-0 {
  left: 0;
  right: 0;
}

.inset-y-0 {
  top: 0;
  bottom: 0;
}

.-bottom-6 {
  bottom: -1.5rem;
}

.-top-px {
  top: -1px;
}

.bottom-0 {
  bottom: 0;
}

.left-0 {
  left: 0;
}

.left-full {
  left: 100%;
}

.right-0 {
  right: 0;
}

.right-1 {
  right: .25rem;
}

.right-full {
  right: 100%;
}

.top-0 {
  top: 0;
}

.top-16 {
  top: 4rem;
}

.top-3 {
  top: .75rem;
}

.top-6 {
  top: 1.5rem;
}

.isolate {
  isolation: isolate;
}

.-z-10 {
  z-index: -10;
}

.z-10 {
  z-index: 10;
}

.z-40 {
  z-index: 40;
}

.z-50 {
  z-index: 50;
}

.order-first {
  order: -9999;
}

.col-span-1 {
  grid-column: span 1 / span 1;
}

.-m-1\.5 {
  margin: -.375rem;
}

.-m-2 {
  margin: -.5rem;
}

.-m-2\.5 {
  margin: -.625rem;
}

.m-auto {
  margin: auto;
}

.-mx-2 {
  margin-left: -.5rem;
  margin-right: -.5rem;
}

.-mx-4 {
  margin-left: -1rem;
  margin-right: -1rem;
}

.-my-2 {
  margin-top: -.5rem;
  margin-bottom: -.5rem;
}

.-my-3 {
  margin-top: -.75rem;
  margin-bottom: -.75rem;
}

.mx-4 {
  margin-left: 1rem;
  margin-right: 1rem;
}

.mx-auto {
  margin-left: auto;
  margin-right: auto;
}

.mb-2 {
  margin-bottom: .5rem;
}

.mb-4 {
  margin-bottom: 1rem;
}

.ml-2 {
  margin-left: .5rem;
}

.ml-3 {
  margin-left: .75rem;
}

.ml-4 {
  margin-left: 1rem;
}

.mr-1\.5 {
  margin-right: .375rem;
}

.mr-16 {
  margin-right: 4rem;
}

.mt-0\.5 {
  margin-top: .125rem;
}

.mt-1 {
  margin-top: .25rem;
}

.mt-10 {
  margin-top: 2.5rem;
}

.mt-12 {
  margin-top: 3rem;
}

.mt-16 {
  margin-top: 4rem;
}

.mt-2 {
  margin-top: .5rem;
}

.mt-2\.5 {
  margin-top: .625rem;
}

.mt-20 {
  margin-top: 5rem;
}

.mt-3 {
  margin-top: .75rem;
}

.mt-4 {
  margin-top: 1rem;
}

.mt-6 {
  margin-top: 1.5rem;
}

.mt-8 {
  margin-top: 2rem;
}

.mt-auto {
  margin-top: auto;
}

.block {
  display: block;
}

.inline-block {
  display: inline-block;
}

.inline {
  display: inline;
}

.flex {
  display: flex;
}

.inline-flex {
  display: inline-flex;
}

.table {
  display: table;
}

.flow-root {
  display: flow-root;
}

.grid {
  display: grid;
}

.contents {
  display: contents;
}

.hidden {
  display: none;
}

.aspect-\[4\/3\] {
  aspect-ratio: 4 / 3;
}

.aspect-\[7\/5\] {
  aspect-ratio: 7 / 5;
}

.size-4 {
  width: 1rem;
  height: 1rem;
}

.size-5 {
  width: 1.25rem;
  height: 1.25rem;
}

.size-6 {
  width: 1.5rem;
  height: 1.5rem;
}

.h-1\.5 {
  height: .375rem;
}

.h-11 {
  height: 2.75rem;
}

.h-12 {
  height: 3rem;
}

.h-14 {
  height: 3.5rem;
}

.h-16 {
  height: 4rem;
}

.h-48 {
  height: 12rem;
}

.h-5 {
  height: 1.25rem;
}

.h-6 {
  height: 1.5rem;
}

.h-7 {
  height: 1.75rem;
}

.h-8 {
  height: 2rem;
}

.h-full {
  height: 100%;
}

.h-px {
  height: 1px;
}

.max-h-20 {
  max-height: 5rem;
}

.min-h-full {
  min-height: 100%;
}

.w-0 {
  width: 0;
}

.w-1\.5 {
  width: .375rem;
}

.w-11 {
  width: 2.75rem;
}

.w-12 {
  width: 3rem;
}

.w-14 {
  width: 3.5rem;
}

.w-16 {
  width: 4rem;
}

.w-32 {
  width: 8rem;
}

.w-36 {
  width: 9rem;
}

.w-48 {
  width: 12rem;
}

.w-5 {
  width: 1.25rem;
}

.w-6 {
  width: 1.5rem;
}

.w-64 {
  width: 16rem;
}

.w-7 {
  width: 1.75rem;
}

.w-8 {
  width: 2rem;
}

.w-96, .w-\[24rem\] {
  width: 24rem;
}

.w-\[37rem\] {
  width: 37rem;
}

.w-full {
  width: 100%;
}

.w-px {
  width: 1px;
}

.w-screen {
  width: 100vw;
}

.min-w-0 {
  min-width: 0;
}

.min-w-12 {
  min-width: 3rem;
}

.min-w-48 {
  min-width: 12rem;
}

.min-w-64 {
  min-width: 16rem;
}

.min-w-80 {
  min-width: 20rem;
}

.min-w-full {
  min-width: 100%;
}

.max-w-0 {
  max-width: 0;
}

.max-w-20 {
  max-width: 5rem;
}

.max-w-2xl {
  max-width: 42rem;
}

.max-w-3xl {
  max-width: 48rem;
}

.max-w-7xl {
  max-width: 80rem;
}

.max-w-80 {
  max-width: 20rem;
}

.max-w-fit {
  max-width: fit-content;
}

.max-w-lg {
  max-width: 32rem;
}

.max-w-none {
  max-width: none;
}

.max-w-screen-xl {
  max-width: 1280px;
}

.max-w-xs {
  max-width: 20rem;
}

.flex-1 {
  flex: 1;
}

.flex-auto {
  flex: auto;
}

.flex-none {
  flex: none;
}

.flex-shrink-0, .shrink-0 {
  flex-shrink: 0;
}

.grow {
  flex-grow: 1;
}

.border-separate {
  border-collapse: separate;
}

.border-spacing-0 {
  --tw-border-spacing-x: 0px;
  --tw-border-spacing-y: 0px;
  border-spacing: var(--tw-border-spacing-x) var(--tw-border-spacing-y);
}

.origin-top-right {
  transform-origin: 100% 0;
}

.-translate-x-full {
  --tw-translate-x: -100%;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.translate-x-0 {
  --tw-translate-x: 0px;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.translate-x-5 {
  --tw-translate-x: 1.25rem;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.translate-y-0 {
  --tw-translate-y: 0px;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.translate-y-4 {
  --tw-translate-y: 1rem;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.scale-100 {
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.scale-95 {
  --tw-scale-x: .95;
  --tw-scale-y: .95;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.transform {
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.cursor-pointer {
  cursor: pointer;
}

.grid-cols-1 {
  grid-template-columns: repeat(1, minmax(0, 1fr));
}

.grid-cols-7 {
  grid-template-columns: repeat(7, minmax(0, 1fr));
}

.grid-rows-1 {
  grid-template-rows: repeat(1, minmax(0, 1fr));
}

.grid-rows-\[1fr\,auto\,1fr\] {
  grid-template-rows: 1fr auto 1fr;
}

.flex-col {
  flex-direction: column;
}

.flex-wrap {
  flex-wrap: wrap;
}

.items-start {
  align-items: flex-start;
}

.items-end {
  align-items: flex-end;
}

.items-center {
  align-items: center;
}

.items-baseline {
  align-items: baseline;
}

.justify-end {
  justify-content: flex-end;
}

.justify-center {
  justify-content: center;
}

.justify-between {
  justify-content: space-between;
}

.gap-5 {
  gap: 1.25rem;
}

.gap-6 {
  gap: 1.5rem;
}

.gap-px {
  gap: 1px;
}

.gap-x-1\.5 {
  column-gap: .375rem;
}

.gap-x-12 {
  column-gap: 3rem;
}

.gap-x-2 {
  column-gap: .5rem;
}

.gap-x-3 {
  column-gap: .75rem;
}

.gap-x-4 {
  column-gap: 1rem;
}

.gap-x-6 {
  column-gap: 1.5rem;
}

.gap-x-8 {
  column-gap: 2rem;
}

.gap-y-16 {
  row-gap: 4rem;
}

.gap-y-2 {
  row-gap: .5rem;
}

.gap-y-5 {
  row-gap: 1.25rem;
}

.gap-y-7 {
  row-gap: 1.75rem;
}

.gap-y-8 {
  row-gap: 2rem;
}

.-space-x-0\.5 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(-.125rem * var(--tw-space-x-reverse));
  margin-left: calc(-.125rem * calc(1 - var(--tw-space-x-reverse)));
}

.space-x-3 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(.75rem * var(--tw-space-x-reverse));
  margin-left: calc(.75rem * calc(1 - var(--tw-space-x-reverse)));
}

.space-x-4 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(1rem * var(--tw-space-x-reverse));
  margin-left: calc(1rem * calc(1 - var(--tw-space-x-reverse)));
}

.space-x-6 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(1.5rem * var(--tw-space-x-reverse));
  margin-left: calc(1.5rem * calc(1 - var(--tw-space-x-reverse)));
}

.space-y-1 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(.25rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(.25rem * var(--tw-space-y-reverse));
}

.space-y-6 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(1.5rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(1.5rem * var(--tw-space-y-reverse));
}

.divide-y > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-y-reverse: 0;
  border-top-width: calc(1px * calc(1 - var(--tw-divide-y-reverse)));
  border-bottom-width: calc(1px * var(--tw-divide-y-reverse));
}

.divide-gray-100 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1;
  border-color: rgb(243 244 246 / var(--tw-divide-opacity));
}

.divide-gray-200 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1;
  border-color: rgb(229 231 235 / var(--tw-divide-opacity));
}

.divide-gray-300 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1;
  border-color: rgb(209 213 219 / var(--tw-divide-opacity));
}

.self-end {
  align-self: flex-end;
}

.self-stretch {
  align-self: stretch;
}

.overflow-hidden {
  overflow: hidden;
}

.overflow-x-auto {
  overflow-x: auto;
}

.overflow-y-auto {
  overflow-y: auto;
}

.truncate {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.whitespace-nowrap {
  white-space: nowrap;
}

.rounded-2xl {
  border-radius: 1rem;
}

.rounded-full {
  border-radius: 9999px;
}

.rounded-lg {
  border-radius: .5rem;
}

.rounded-md {
  border-radius: .375rem;
}

.rounded-xl {
  border-radius: .75rem;
}

.rounded-l-md {
  border-top-left-radius: .375rem;
  border-bottom-left-radius: .375rem;
}

.rounded-r-md {
  border-top-right-radius: .375rem;
  border-bottom-right-radius: .375rem;
}

.rounded-bl-lg {
  border-bottom-left-radius: .5rem;
}

.rounded-br-lg {
  border-bottom-right-radius: .5rem;
}

.rounded-tl-lg {
  border-top-left-radius: .5rem;
}

.rounded-tr-lg {
  border-top-right-radius: .5rem;
}

.border {
  border-width: 1px;
}

.border-2 {
  border-width: 2px;
}

.border-b {
  border-bottom-width: 1px;
}

.border-r {
  border-right-width: 1px;
}

.border-t {
  border-top-width: 1px;
}

.border-border {
  border-color: rgba(var(--border));
}

.border-gray-100 {
  --tw-border-opacity: 1;
  border-color: rgb(243 244 246 / var(--tw-border-opacity));
}

.border-gray-200 {
  --tw-border-opacity: 1;
  border-color: rgb(229 231 235 / var(--tw-border-opacity));
}

.border-gray-300 {
  --tw-border-opacity: 1;
  border-color: rgb(209 213 219 / var(--tw-border-opacity));
}

.border-gray-900\/10 {
  border-color: #1118271a;
}

.border-gray-900\/5 {
  border-color: #1118270d;
}

.border-indigo-400 {
  --tw-border-opacity: 1;
  border-color: rgb(129 140 248 / var(--tw-border-opacity));
}

.border-transparent {
  border-color: #0000;
}

.border-b-gray-900\/10 {
  border-bottom-color: #1118271a;
}

.bg-\[\#000000\] {
  --tw-bg-opacity: 1;
  background-color: rgb(0 0 0 / var(--tw-bg-opacity));
}

.bg-\[\#0000FF\] {
  --tw-bg-opacity: 1;
  background-color: rgb(0 0 255 / var(--tw-bg-opacity));
}

.bg-\[\#00FF00\] {
  --tw-bg-opacity: 1;
  background-color: rgb(0 255 0 / var(--tw-bg-opacity));
}

.bg-\[\#00FFFF\] {
  --tw-bg-opacity: 1;
  background-color: rgb(0 255 255 / var(--tw-bg-opacity));
}

.bg-\[\#012551\] {
  --tw-bg-opacity: 1;
  background-color: rgb(1 37 81 / var(--tw-bg-opacity));
}

.bg-\[\#67EF67\] {
  --tw-bg-opacity: 1;
  background-color: rgb(103 239 103 / var(--tw-bg-opacity));
}

.bg-\[\#808080\] {
  --tw-bg-opacity: 1;
  background-color: rgb(128 128 128 / var(--tw-bg-opacity));
}

.bg-\[\#A020F0\] {
  --tw-bg-opacity: 1;
  background-color: rgb(160 32 240 / var(--tw-bg-opacity));
}

.bg-\[\#EEEEEE\] {
  --tw-bg-opacity: 1;
  background-color: rgb(238 238 238 / var(--tw-bg-opacity));
}

.bg-\[\#FF0000\] {
  --tw-bg-opacity: 1;
  background-color: rgb(255 0 0 / var(--tw-bg-opacity));
}

.bg-\[\#FF00FF\] {
  --tw-bg-opacity: 1;
  background-color: rgb(255 0 255 / var(--tw-bg-opacity));
}

.bg-\[\#FFA500\] {
  --tw-bg-opacity: 1;
  background-color: rgb(255 165 0 / var(--tw-bg-opacity));
}

.bg-\[\#FFFF00\] {
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 0 / var(--tw-bg-opacity));
}

.bg-active {
  background-color: rgba(var(--active));
}

.bg-background-button {
  background-color: rgba(var(--background-button));
}

.bg-background-button\/60 {
  background-color: rgba(var(--background-button), .6);
}

.bg-background-card {
  background-color: rgba(var(--background-card));
}

.bg-background-nav {
  background-color: rgba(var(--background-nav));
}

.bg-border {
  background-color: rgba(var(--border));
}

.bg-emerald-500 {
  --tw-bg-opacity: 1;
  background-color: rgb(16 185 129 / var(--tw-bg-opacity));
}

.bg-emerald-500\/20 {
  background-color: #10b98133;
}

.bg-gray-100 {
  --tw-bg-opacity: 1;
  background-color: rgb(243 244 246 / var(--tw-bg-opacity));
}

.bg-gray-200 {
  --tw-bg-opacity: 1;
  background-color: rgb(229 231 235 / var(--tw-bg-opacity));
}

.bg-gray-50 {
  --tw-bg-opacity: 1;
  background-color: rgb(249 250 251 / var(--tw-bg-opacity));
}

.bg-gray-500 {
  --tw-bg-opacity: 1;
  background-color: rgb(107 114 128 / var(--tw-bg-opacity));
}

.bg-gray-900 {
  --tw-bg-opacity: 1;
  background-color: rgb(17 24 39 / var(--tw-bg-opacity));
}

.bg-gray-900\/5 {
  background-color: #1118270d;
}

.bg-gray-900\/80 {
  background-color: #111827cc;
}

.bg-green-50 {
  --tw-bg-opacity: 1;
  background-color: rgb(240 253 244 / var(--tw-bg-opacity));
}

.bg-green-500 {
  --tw-bg-opacity: 1;
  background-color: rgb(34 197 94 / var(--tw-bg-opacity));
}

.bg-green-600 {
  --tw-bg-opacity: 1;
  background-color: rgb(22 163 74 / var(--tw-bg-opacity));
}

.bg-indigo-500 {
  --tw-bg-opacity: 1;
  background-color: rgb(99 102 241 / var(--tw-bg-opacity));
}

.bg-indigo-600 {
  --tw-bg-opacity: 1;
  background-color: rgb(79 70 229 / var(--tw-bg-opacity));
}

.bg-pink-600 {
  --tw-bg-opacity: 1;
  background-color: rgb(219 39 119 / var(--tw-bg-opacity));
}

.bg-purple-600 {
  --tw-bg-opacity: 1;
  background-color: rgb(147 51 234 / var(--tw-bg-opacity));
}

.bg-red-50 {
  --tw-bg-opacity: 1;
  background-color: rgb(254 242 242 / var(--tw-bg-opacity));
}

.bg-transparent {
  background-color: #0000;
}

.bg-white {
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity));
}

.bg-yellow-50 {
  --tw-bg-opacity: 1;
  background-color: rgb(254 252 232 / var(--tw-bg-opacity));
}

.bg-yellow-500 {
  --tw-bg-opacity: 1;
  background-color: rgb(234 179 8 / var(--tw-bg-opacity));
}

.bg-opacity-75 {
  --tw-bg-opacity: .75;
}

.fill-active {
  fill: rgba(var(--active));
}

.fill-active-text {
  fill: rgba(var(--active-text));
}

.object-cover {
  object-fit: cover;
}

.object-scale-down {
  object-fit: scale-down;
}

.object-top {
  object-position: top;
}

.p-1 {
  padding: .25rem;
}

.p-1\.5 {
  padding: .375rem;
}

.p-2 {
  padding: .5rem;
}

.p-2\.5 {
  padding: .625rem;
}

.p-3 {
  padding: .75rem;
}

.p-4 {
  padding: 1rem;
}

.p-6 {
  padding: 1.5rem;
}

.px-0 {
  padding-left: 0;
  padding-right: 0;
}

.px-2 {
  padding-left: .5rem;
  padding-right: .5rem;
}

.px-3 {
  padding-left: .75rem;
  padding-right: .75rem;
}

.px-3\.5 {
  padding-left: .875rem;
  padding-right: .875rem;
}

.px-4 {
  padding-left: 1rem;
  padding-right: 1rem;
}

.px-6 {
  padding-left: 1.5rem;
  padding-right: 1.5rem;
}

.px-8 {
  padding-left: 2rem;
  padding-right: 2rem;
}

.py-0\.5 {
  padding-top: .125rem;
  padding-bottom: .125rem;
}

.py-1 {
  padding-top: .25rem;
  padding-bottom: .25rem;
}

.py-1\.5 {
  padding-top: .375rem;
  padding-bottom: .375rem;
}

.py-10 {
  padding-top: 2.5rem;
  padding-bottom: 2.5rem;
}

.py-12 {
  padding-top: 3rem;
  padding-bottom: 3rem;
}

.py-16 {
  padding-top: 4rem;
  padding-bottom: 4rem;
}

.py-2 {
  padding-top: .5rem;
  padding-bottom: .5rem;
}

.py-2\.5 {
  padding-top: .625rem;
  padding-bottom: .625rem;
}

.py-24 {
  padding-top: 6rem;
  padding-bottom: 6rem;
}

.py-3 {
  padding-top: .75rem;
  padding-bottom: .75rem;
}

.py-3\.5 {
  padding-top: .875rem;
  padding-bottom: .875rem;
}

.py-32 {
  padding-top: 8rem;
  padding-bottom: 8rem;
}

.py-4 {
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.py-5 {
  padding-top: 1.25rem;
  padding-bottom: 1.25rem;
}

.py-6 {
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
}

.py-8 {
  padding-top: 2rem;
  padding-bottom: 2rem;
}

.pb-4 {
  padding-bottom: 1rem;
}

.pb-6 {
  padding-bottom: 1.5rem;
}

.pl-3 {
  padding-left: .75rem;
}

.pl-4 {
  padding-left: 1rem;
}

.pl-6 {
  padding-left: 1.5rem;
}

.pl-8 {
  padding-left: 2rem;
}

.pr-0 {
  padding-right: 0;
}

.pr-10 {
  padding-right: 2.5rem;
}

.pr-2 {
  padding-right: .5rem;
}

.pr-3 {
  padding-right: .75rem;
}

.pr-4 {
  padding-right: 1rem;
}

.pr-6 {
  padding-right: 1.5rem;
}

.pt-1 {
  padding-top: .25rem;
}

.pt-10 {
  padding-top: 2.5rem;
}

.pt-3\.5 {
  padding-top: .875rem;
}

.pt-4 {
  padding-top: 1rem;
}

.pt-5 {
  padding-top: 1.25rem;
}

.pt-6 {
  padding-top: 1.5rem;
}

.text-left {
  text-align: left;
}

.text-center {
  text-align: center;
}

.text-right {
  text-align: right;
}

.align-top {
  vertical-align: top;
}

.align-middle {
  vertical-align: middle;
}

.text-2xl {
  font-size: 1.5rem;
  line-height: 2rem;
}

.text-3xl {
  font-size: 1.875rem;
  line-height: 2.25rem;
}

.text-4xl {
  font-size: 2.25rem;
  line-height: 2.5rem;
}

.text-5xl {
  font-size: 3rem;
  line-height: 1;
}

.text-\[0\.625rem\] {
  font-size: .625rem;
}

.text-base {
  font-size: 1rem;
  line-height: 1.5rem;
}

.text-lg {
  font-size: 1.125rem;
  line-height: 1.75rem;
}

.text-sm {
  font-size: .875rem;
  line-height: 1.25rem;
}

.text-xl {
  font-size: 1.25rem;
  line-height: 1.75rem;
}

.text-xs {
  font-size: .75rem;
  line-height: 1rem;
}

.font-bold {
  font-weight: 700;
}

.font-medium {
  font-weight: 500;
}

.font-normal {
  font-weight: 400;
}

.font-semibold {
  font-weight: 600;
}

.italic {
  font-style: italic;
}

.tabular-nums {
  --tw-numeric-spacing: tabular-nums;
  font-variant-numeric: var(--tw-ordinal) var(--tw-slashed-zero) var(--tw-numeric-figure) var(--tw-numeric-spacing) var(--tw-numeric-fraction);
}

.leading-10 {
  line-height: 2.5rem;
}

.leading-5 {
  line-height: 1.25rem;
}

.leading-6 {
  line-height: 1.5rem;
}

.leading-7 {
  line-height: 1.75rem;
}

.leading-8 {
  line-height: 2rem;
}

.leading-none {
  line-height: 1;
}

.tracking-tight {
  letter-spacing: -.025em;
}

.text-\[\#000000\] {
  --tw-text-opacity: 1;
  color: rgb(0 0 0 / var(--tw-text-opacity));
}

.text-\[\#FFFFFF\] {
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
}

.text-active-text {
  color: rgba(var(--active-text));
}

.text-active-text\/60 {
  color: rgba(var(--active-text), .6);
}

.text-gray-200 {
  --tw-text-opacity: 1;
  color: rgb(229 231 235 / var(--tw-text-opacity));
}

.text-gray-300 {
  --tw-text-opacity: 1;
  color: rgb(209 213 219 / var(--tw-text-opacity));
}

.text-gray-400 {
  --tw-text-opacity: 1;
  color: rgb(156 163 175 / var(--tw-text-opacity));
}

.text-gray-500 {
  --tw-text-opacity: 1;
  color: rgb(107 114 128 / var(--tw-text-opacity));
}

.text-gray-600 {
  --tw-text-opacity: 1;
  color: rgb(75 85 99 / var(--tw-text-opacity));
}

.text-gray-700 {
  --tw-text-opacity: 1;
  color: rgb(55 65 81 / var(--tw-text-opacity));
}

.text-gray-900 {
  --tw-text-opacity: 1;
  color: rgb(17 24 39 / var(--tw-text-opacity));
}

.text-green-600 {
  --tw-text-opacity: 1;
  color: rgb(22 163 74 / var(--tw-text-opacity));
}

.text-green-700 {
  --tw-text-opacity: 1;
  color: rgb(21 128 61 / var(--tw-text-opacity));
}

.text-indigo-200 {
  --tw-text-opacity: 1;
  color: rgb(199 210 254 / var(--tw-text-opacity));
}

.text-indigo-600 {
  --tw-text-opacity: 1;
  color: rgb(79 70 229 / var(--tw-text-opacity));
}

.text-red-600 {
  --tw-text-opacity: 1;
  color: rgb(220 38 38 / var(--tw-text-opacity));
}

.text-red-700 {
  --tw-text-opacity: 1;
  color: rgb(185 28 28 / var(--tw-text-opacity));
}

.text-rose-600 {
  --tw-text-opacity: 1;
  color: rgb(225 29 72 / var(--tw-text-opacity));
}

.text-text-button {
  color: rgba(var(--text-button));
}

.text-text-primary {
  color: rgba(var(--text-primary));
}

.text-text-primary\/80 {
  color: rgba(var(--text-primary), .8);
}

.text-text-secondary {
  color: rgba(var(--text-secondary));
}

.text-white {
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
}

.text-yellow-700 {
  --tw-text-opacity: 1;
  color: rgb(161 98 7 / var(--tw-text-opacity));
}

.opacity-0 {
  opacity: 0;
}

.opacity-100 {
  opacity: 1;
}

.shadow {
  --tw-shadow: 0 1px 3px 0 #0000001a, 0 1px 2px -1px #0000001a;
  --tw-shadow-colored: 0 1px 3px 0 var(--tw-shadow-color), 0 1px 2px -1px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.shadow-lg {
  --tw-shadow: 0 10px 15px -3px #0000001a, 0 4px 6px -4px #0000001a;
  --tw-shadow-colored: 0 10px 15px -3px var(--tw-shadow-color), 0 4px 6px -4px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.shadow-xl {
  --tw-shadow: 0 20px 25px -5px #0000001a, 0 8px 10px -6px #0000001a;
  --tw-shadow-colored: 0 20px 25px -5px var(--tw-shadow-color), 0 8px 10px -6px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.ring-0 {
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(0px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
}

.ring-1 {
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(1px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
}

.ring-2 {
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(2px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
}

.ring-inset {
  --tw-ring-inset: inset;
}

.ring-black {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(0 0 0 / var(--tw-ring-opacity));
}

.ring-gray-200 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(229 231 235 / var(--tw-ring-opacity));
}

.ring-gray-300 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(209 213 219 / var(--tw-ring-opacity));
}

.ring-gray-500\/10 {
  --tw-ring-color: #6b72801a;
}

.ring-gray-900\/10 {
  --tw-ring-color: #1118271a;
}

.ring-gray-900\/5 {
  --tw-ring-color: #1118270d;
}

.ring-green-600\/20 {
  --tw-ring-color: #16a34a33;
}

.ring-red-600\/10 {
  --tw-ring-color: #dc26261a;
}

.ring-red-600\/20 {
  --tw-ring-color: #dc262633;
}

.ring-white {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(255 255 255 / var(--tw-ring-opacity));
}

.ring-yellow-600\/20 {
  --tw-ring-color: #ca8a0433;
}

.ring-opacity-5 {
  --tw-ring-opacity: .05;
}

.backdrop-blur {
  --tw-backdrop-blur: blur(8px);
  backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
}

.backdrop-filter {
  backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
}

.transition {
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, backdrop-filter;
  transition-duration: .15s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.transition-all {
  transition-property: all;
  transition-duration: .15s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.transition-colors {
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke;
  transition-duration: .15s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.transition-opacity {
  transition-property: opacity;
  transition-duration: .15s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.duration-100 {
  transition-duration: .1s;
}

.duration-200 {
  transition-duration: .2s;
}

.duration-300 {
  transition-duration: .3s;
}

.duration-75 {
  transition-duration: 75ms;
}

.ease-in {
  transition-timing-function: cubic-bezier(.4, 0, 1, 1);
}

.ease-in-out {
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.ease-linear {
  transition-timing-function: linear;
}

.ease-out {
  transition-timing-function: cubic-bezier(0, 0, .2, 1);
}

.light {
  --background: 255, 255, 255;
  --background-nav: 245, 245, 245;
  --background-card: 255, 255, 255;
  --background-button: 37, 99, 235;
  --background-button-hover: 74, 123, 232;
  --border: 229, 231, 235;
  --shadow: 0 2px 4px 0 #00000015;
  --tw-shadow-color: 229, 231, 235;
  --tw-ring-offset-shadow: 0 0 #0000;
  --tw-ring-shadow: 0 0 #0000;
  --card: 255, 255, 255;
  --copy-primary: 23, 23, 23;
  --copy-secondary: 115, 115, 115;
  --cta: 139, 92, 246;
  --active: 249, 250, 251;
  --active-text: 37, 99, 235;
  --hover-item: 249, 250, 251;
  --hover-text: 37, 99, 235;
  --text-primary: 55, 65, 81;
  --text-secondary: 107, 114, 128;
  --text-button: 255, 255, 255;
  --text-button-hover: 55, 65, 81;
  background: rgba(var(--background));
}

.dark {
  --background: 17, 24, 39;
  --background-nav: 0, 0, 0;
  --background-card: 31, 41, 55;
  --background-button: 37, 99, 235;
  --background-button-hover: 37, 99, 235 / 80%;
  --border: 148, 163, 184;
  --shadow: 0 2px 4px 0 #000;
  --tw-shadow-color: 229, 231, 235;
  --tw-ring-offset-shadow: 0 0 #0000;
  --tw-ring-shadow: 0 0 #0000;
  --card: 23, 23, 23;
  --copy-primary: 250, 250, 250;
  --copy-secondary: 115, 115, 115;
  --cta: 99, 102, 241;
  --active: 31 41 55;
  --active-text: 255, 255, 255;
  --hover-item: 31 41 55;
  --hover-text: 255, 255, 255;
  --text-primary: 156, 163, 175;
  --text-secondary: 107, 114, 128;
  --text-button: 156, 163, 175;
  --text-button-hover: 55, 65, 81;
  background: rgba(var(--background));
}

.header-background-filters {
  filter: opacity(.9) brightness(.4);
}

.slideshow {
  background-image: url("bg-1.1dc0883a.png");
  background-repeat: no-repeat;
  background-size: cover;
  animation: 15s ease-in-out infinite slideshow;
}

@keyframes slideshow {
  0% {
    background-image: url("bg-1.1dc0883a.png");
  }

  25% {
    background-image: url("bg-2.f8e6f23d.png");
  }

  50% {
    background-image: url("bg-3.dc995abe.png");
  }

  75% {
    background-image: url("bg-6.afd9230f.png");
  }

  100% {
    background-image: url("bg-1.1dc0883a.png");
  }
}

.shadow-sm {
  --tw-shadow: var(--shadow);
  --tw-shadow-colored: 0 1px 2px 0 var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

@font-face {
  font-family: Noto Color Emoji;
  src: url("NotoColorEmoji-Regular.c22bf752.ttf");
  unicode-range: U+1F1E6-1F1FF;
}

.country {
  font-family: Noto Color Emoji;
}

.even\:bg-gray-50:nth-child(2n) {
  --tw-bg-opacity: 1;
  background-color: rgb(249 250 251 / var(--tw-bg-opacity));
}

.hover\:bg-background-button-hover:hover {
  background-color: rgba(var(--background-button-hover));
}

.hover\:bg-gray-100:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(243 244 246 / var(--tw-bg-opacity));
}

.hover\:bg-gray-50:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(249 250 251 / var(--tw-bg-opacity));
}

.hover\:bg-green-500:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(34 197 94 / var(--tw-bg-opacity));
}

.hover\:bg-hover-item:hover {
  background-color: rgba(var(--hover-item));
}

.hover\:bg-indigo-500:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(99 102 241 / var(--tw-bg-opacity));
}

.hover\:text-active-text:hover {
  color: rgba(var(--active-text));
}

.hover\:text-gray-500:hover {
  --tw-text-opacity: 1;
  color: rgb(107 114 128 / var(--tw-text-opacity));
}

.hover\:text-gray-600:hover {
  --tw-text-opacity: 1;
  color: rgb(75 85 99 / var(--tw-text-opacity));
}

.hover\:text-gray-700:hover {
  --tw-text-opacity: 1;
  color: rgb(55 65 81 / var(--tw-text-opacity));
}

.hover\:text-hover-text:hover {
  color: rgba(var(--hover-text));
}

.hover\:underline:hover {
  text-decoration-line: underline;
}

.focus\:z-10:focus {
  z-index: 10;
}

.focus\:outline-none:focus {
  outline-offset: 2px;
  outline: 2px solid #0000;
}

.focus\:ring-2:focus {
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(2px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
}

.focus\:ring-background-button:focus {
  --tw-ring-color: rgba(var(--background-button));
}

.focus\:ring-indigo-500:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(99 102 241 / var(--tw-ring-opacity));
}

.focus\:ring-indigo-600:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(79 70 229 / var(--tw-ring-opacity));
}

.focus\:ring-offset-2:focus {
  --tw-ring-offset-width: 2px;
}

.focus-visible\:outline:focus-visible {
  outline-style: solid;
}

.focus-visible\:outline-2:focus-visible {
  outline-width: 2px;
}

.focus-visible\:outline-offset-2:focus-visible {
  outline-offset: 2px;
}

.focus-visible\:outline-indigo-600:focus-visible {
  outline-color: #4f46e5;
}

.disabled\:cursor-not-allowed:disabled {
  cursor: not-allowed;
}

.disabled\:opacity-30:disabled {
  opacity: .3;
}

.disabled\:hover\:bg-white:hover:disabled {
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity));
}

@media (width >= 640px) {
  .sm\:-mx-0 {
    margin-left: 0;
    margin-right: 0;
  }

  .sm\:-mx-6 {
    margin-left: -1.5rem;
    margin-right: -1.5rem;
  }

  .sm\:mx-0 {
    margin-left: 0;
    margin-right: 0;
  }

  .sm\:my-8 {
    margin-top: 2rem;
    margin-bottom: 2rem;
  }

  .sm\:ml-3 {
    margin-left: .75rem;
  }

  .sm\:ml-4 {
    margin-left: 1rem;
  }

  .sm\:mt-0 {
    margin-top: 0;
  }

  .sm\:mt-24 {
    margin-top: 6rem;
  }

  .sm\:mt-6 {
    margin-top: 1.5rem;
  }

  .sm\:block {
    display: block;
  }

  .sm\:flex {
    display: flex;
  }

  .sm\:table-cell {
    display: table-cell;
  }

  .sm\:hidden {
    display: none;
  }

  .sm\:w-0 {
    width: 0;
  }

  .sm\:w-auto {
    width: auto;
  }

  .sm\:w-full {
    width: 100%;
  }

  .sm\:max-w-lg {
    max-width: 32rem;
  }

  .sm\:max-w-none {
    max-width: none;
  }

  .sm\:flex-auto {
    flex: auto;
  }

  .sm\:translate-y-0 {
    --tw-translate-y: 0px;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .sm\:scale-100 {
    --tw-scale-x: 1;
    --tw-scale-y: 1;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .sm\:scale-95 {
    --tw-scale-x: .95;
    --tw-scale-y: .95;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .sm\:grid-cols-2 {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }

  .sm\:grid-cols-3 {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }

  .sm\:flex-row-reverse {
    flex-direction: row-reverse;
  }

  .sm\:flex-col {
    flex-direction: column;
  }

  .sm\:items-start {
    align-items: flex-start;
  }

  .sm\:items-end {
    align-items: flex-end;
  }

  .sm\:items-center {
    align-items: center;
  }

  .sm\:gap-6 {
    gap: 1.5rem;
  }

  .sm\:gap-8 {
    gap: 2rem;
  }

  .sm\:gap-x-6 {
    column-gap: 1.5rem;
  }

  .sm\:rounded-lg {
    border-radius: .5rem;
  }

  .sm\:rounded-xl {
    border-radius: .75rem;
  }

  .sm\:border-l {
    border-left-width: 1px;
  }

  .sm\:border-t {
    border-top-width: 1px;
  }

  .sm\:border-gray-900\/5 {
    border-color: #1118270d;
  }

  .sm\:p-0 {
    padding: 0;
  }

  .sm\:p-6 {
    padding: 1.5rem;
  }

  .sm\:px-6 {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }

  .sm\:px-8 {
    padding-left: 2rem;
    padding-right: 2rem;
  }

  .sm\:py-32 {
    padding-top: 8rem;
    padding-bottom: 8rem;
  }

  .sm\:py-40 {
    padding-top: 10rem;
    padding-bottom: 10rem;
  }

  .sm\:pb-14 {
    padding-bottom: 3.5rem;
  }

  .sm\:pb-4 {
    padding-bottom: 1rem;
  }

  .sm\:pl-0 {
    padding-left: 0;
  }

  .sm\:pl-3 {
    padding-left: .75rem;
  }

  .sm\:pl-4 {
    padding-left: 1rem;
  }

  .sm\:pl-6 {
    padding-left: 1.5rem;
  }

  .sm\:pr-0 {
    padding-right: 0;
  }

  .sm\:pr-4 {
    padding-right: 1rem;
  }

  .sm\:pr-6 {
    padding-right: 1.5rem;
  }

  .sm\:pr-8 {
    padding-right: 2rem;
  }

  .sm\:pt-10 {
    padding-top: 2.5rem;
  }

  .sm\:pt-6 {
    padding-top: 1.5rem;
  }

  .sm\:text-left {
    text-align: left;
  }

  .sm\:text-4xl {
    font-size: 2.25rem;
    line-height: 2.5rem;
  }

  .sm\:text-5xl {
    font-size: 3rem;
    line-height: 1;
  }

  .sm\:text-6xl {
    font-size: 3.75rem;
    line-height: 1;
  }
}

@media (width >= 768px) {
  .md\:order-1 {
    order: 1;
  }

  .md\:order-2 {
    order: 2;
  }

  .md\:mx-auto {
    margin-left: auto;
    margin-right: auto;
  }

  .md\:mt-0 {
    margin-top: 0;
  }

  .md\:mt-24 {
    margin-top: 6rem;
  }

  .md\:block {
    display: block;
  }

  .md\:flex {
    display: flex;
  }

  .md\:h-32 {
    height: 8rem;
  }

  .md\:w-32 {
    width: 8rem;
  }

  .md\:items-center {
    align-items: center;
  }

  .md\:justify-between {
    justify-content: space-between;
  }

  .md\:text-5xl {
    font-size: 3rem;
    line-height: 1;
  }

  .md\:text-9xl {
    font-size: 8rem;
    line-height: 1;
  }
}

@media (width >= 1024px) {
  .lg\:fixed {
    position: fixed;
  }

  .lg\:relative {
    position: relative;
  }

  .lg\:inset-y-0 {
    top: 0;
    bottom: 0;
  }

  .lg\:z-50 {
    z-index: 50;
  }

  .lg\:col-span-2 {
    grid-column: span 2 / span 2;
  }

  .lg\:col-span-7 {
    grid-column: span 7 / span 7;
  }

  .lg\:col-start-1 {
    grid-column-start: 1;
  }

  .lg\:col-start-2 {
    grid-column-start: 2;
  }

  .lg\:col-start-3 {
    grid-column-start: 3;
  }

  .lg\:col-start-8 {
    grid-column-start: 8;
  }

  .lg\:col-end-1 {
    grid-column-end: 1;
  }

  .lg\:col-end-13 {
    grid-column-end: 13;
  }

  .lg\:col-end-2 {
    grid-column-end: 2;
  }

  .lg\:row-span-2 {
    grid-row: span 2 / span 2;
  }

  .lg\:row-start-1 {
    grid-row-start: 1;
  }

  .lg\:row-start-2 {
    grid-row-start: 2;
  }

  .lg\:row-end-1 {
    grid-row-end: 1;
  }

  .lg\:row-end-2 {
    grid-row-end: 2;
  }

  .lg\:row-end-4 {
    grid-row-end: 4;
  }

  .lg\:-mx-8 {
    margin-left: -2rem;
    margin-right: -2rem;
  }

  .lg\:mx-0 {
    margin-left: 0;
    margin-right: 0;
  }

  .lg\:ml-auto {
    margin-left: auto;
  }

  .lg\:mt-0 {
    margin-top: 0;
  }

  .lg\:mt-9 {
    margin-top: 2.25rem;
  }

  .lg\:block {
    display: block;
  }

  .lg\:flex {
    display: flex;
  }

  .lg\:table-cell {
    display: table-cell;
  }

  .lg\:grid {
    display: grid;
  }

  .lg\:contents {
    display: contents;
  }

  .lg\:hidden {
    display: none;
  }

  .lg\:h-6 {
    height: 1.5rem;
  }

  .lg\:w-72 {
    width: 18rem;
  }

  .lg\:w-\[37rem\] {
    width: 37rem;
  }

  .lg\:w-auto {
    width: auto;
  }

  .lg\:w-full {
    width: 100%;
  }

  .lg\:w-px {
    width: 1px;
  }

  .lg\:min-w-full {
    min-width: 100%;
  }

  .lg\:max-w-4xl {
    max-width: 56rem;
  }

  .lg\:max-w-lg {
    max-width: 32rem;
  }

  .lg\:max-w-none {
    max-width: none;
  }

  .lg\:flex-auto {
    flex: auto;
  }

  .lg\:flex-none {
    flex: none;
  }

  .lg\:flex-grow {
    flex-grow: 1;
  }

  .lg\:grid-cols-12 {
    grid-template-columns: repeat(12, minmax(0, 1fr));
  }

  .lg\:grid-cols-2 {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }

  .lg\:grid-cols-3 {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }

  .lg\:grid-cols-4 {
    grid-template-columns: repeat(4, minmax(0, 1fr));
  }

  .lg\:grid-cols-\[max\(50\%\,36rem\)\,1fr\] {
    grid-template-columns: max(50%, 36rem) 1fr;
  }

  .lg\:flex-col {
    flex-direction: column;
  }

  .lg\:items-start {
    align-items: flex-start;
  }

  .lg\:items-center {
    align-items: center;
  }

  .lg\:justify-end {
    justify-content: flex-end;
  }

  .lg\:gap-8 {
    gap: 2rem;
  }

  .lg\:gap-x-10 {
    column-gap: 2.5rem;
  }

  .lg\:gap-x-16 {
    column-gap: 4rem;
  }

  .lg\:gap-x-6 {
    column-gap: 1.5rem;
  }

  .lg\:gap-x-8 {
    column-gap: 2rem;
  }

  .lg\:gap-y-8 {
    row-gap: 2rem;
  }

  .lg\:self-end {
    align-self: flex-end;
  }

  .lg\:border-l {
    border-left-width: 1px;
  }

  .lg\:border-t {
    border-top-width: 1px;
  }

  .lg\:border-t-0 {
    border-top-width: 0;
  }

  .lg\:border-t-gray-900\/5 {
    border-top-color: #1118270d;
  }

  .lg\:px-2 {
    padding-left: .5rem;
    padding-right: .5rem;
  }

  .lg\:px-4 {
    padding-left: 1rem;
    padding-right: 1rem;
  }

  .lg\:px-8 {
    padding-left: 2rem;
    padding-right: 2rem;
  }

  .lg\:pb-8 {
    padding-bottom: 2rem;
  }

  .lg\:pl-72 {
    padding-left: 18rem;
  }

  .lg\:pl-8 {
    padding-left: 2rem;
  }

  .lg\:pr-8 {
    padding-right: 2rem;
  }
}

@media (width >= 1224px) {
  .min-\[1224px\]\:grid-cols-3 {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }
}

@media (width >= 1280px) {
  .xl\:static {
    position: static;
  }

  .xl\:relative {
    position: relative;
  }

  .xl\:right-auto {
    right: auto;
  }

  .xl\:top-auto {
    top: auto;
  }

  .xl\:col-span-8 {
    grid-column: span 8 / span 8;
  }

  .xl\:col-start-9 {
    grid-column-start: 9;
  }

  .xl\:ml-3\.5 {
    margin-left: .875rem;
  }

  .xl\:mt-0 {
    margin-top: 0;
  }

  .xl\:block {
    display: block;
  }

  .xl\:hidden {
    display: none;
  }

  .xl\:max-w-6xl {
    max-width: 72rem;
  }

  .xl\:flex-row {
    flex-direction: row;
  }

  .xl\:gap-x-8 {
    column-gap: 2rem;
  }

  .xl\:self-center {
    align-self: center;
  }

  .xl\:border-l {
    border-left-width: 1px;
  }

  .xl\:border-gray-400 {
    --tw-border-opacity: 1;
    border-color: rgb(156 163 175 / var(--tw-border-opacity));
  }

  .xl\:border-opacity-50 {
    --tw-border-opacity: .5;
  }

  .xl\:px-0 {
    padding-left: 0;
    padding-right: 0;
  }

  .xl\:px-16 {
    padding-left: 4rem;
    padding-right: 4rem;
  }

  .xl\:px-8 {
    padding-left: 2rem;
    padding-right: 2rem;
  }

  .xl\:pb-20 {
    padding-bottom: 5rem;
  }

  .xl\:pl-3\.5 {
    padding-left: .875rem;
  }

  .xl\:pr-0 {
    padding-right: 0;
  }

  .xl\:pt-16 {
    padding-top: 4rem;
  }
}

@media (width >= 1536px) {
  .\32 xl\:grid-cols-4 {
    grid-template-columns: repeat(4, minmax(0, 1fr));
  }
}
/*# sourceMappingURL=index.4d17b987.css.map */
